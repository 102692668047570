import Cookies from 'js-cookie'
import { getOrderList, getDiscountList } from '@/api/order'
// eslint-disable-next-line no-unused-vars
import * as socket from '@/socket/order'
import moment from 'moment'

const state = {
  shopCart: [],
  totalAmount: 0,
  orderSeat: null,
  orderList: [],  // 工作區訂單列表
  waitCheckOutList: [], // 待結帳訂單列表
  checkOutList: [], // 結帳訂單列表
  waitConfirmList: [], // 待確認訂單列表
  waitConfirmSoundOn: false, // 待確認訂單聲音開關
  badgeList: {  // footer的badge數字
    workstation: 0, // 工作區訂單
    waitCheckOut: 0, // 待結帳訂單
    waitConfirm: 0, // 待確認訂單
  },
  orderType: {
    1: { name: '內用' },
    2: { name: '外帶熟食' },
    3: { name: '外帶生食' },
    4: { name: 'Uber熟食' },
    5: { name: 'Uber生食' },
  },

  // 折扣列表
  discountList: [],

}

const mutations = {
  // 增加一項商品到購物車
  ADD_SHOPCART(state, payload) {
    state.shopCart.push(payload)
    // 增加總金額
    state.totalAmount += payload.price * payload.quantity
    // 將購物車資料轉JSON存入cookie 限時2小時
    Cookies.set('shopCart', JSON.stringify(state.shopCart), { expires: 2 / 24 })
  },
  // 從cookie取出購物車資料
  GET_SHOPCART_COOKIE(state) {
    // console.log("從cookie取出購物車資料")
    const shopCart = Cookies.get('shopCart')
    if (shopCart) {
      state.shopCart = JSON.parse(shopCart)
    }
  },
  // 變更其中一項商品
  UPDATE_SET_ITEM(state, { index, product }) {
    // console.log('UPDATE_SET_ITEM', index, product)
    state.shopCart[index] = product
  },
  // 變更總金額
  UPDATE_TOTAL_AMOUNT(state) {
    state.totalAmount = 0
    state.shopCart.forEach((item) => {
      state.totalAmount += item.price * item.quantity
    })
  },
  // 刪除購物車商品
  DELETE_PRODUCT(state, index) {
    state.shopCart.splice(index, 1)
  },
  // 購物車資料存入cookie
  SAVE_SHOPCART(state) {
    Cookies.set('shopCart', JSON.stringify(state.shopCart), { expires: 2 / 24 })
  },
  // 清空購物車
  RESET_SHOPCART(state) {
    state.shopCart = []
    state.totalAmount = 0
    Cookies.remove('shopCart')
  },
  // 設定訂單座位
  SET_ORDER_SEAT(state, payload) {
    state.orderSeat = payload
  },
  // 設定訂單列表
  SET_ORDER_LIST(state, payload) {
    state.badgeList.workstation = payload.length
    state.orderList = payload
  },
  // 設定待結帳訂單列表
  SET_WAIT_CHECKOUT_LIST(state, payload) {
    state.badgeList.waitCheckOut = payload.length
    state.waitCheckOutList = payload
  },
  // 設定結帳訂單列表
  SET_CHECKOUT_LIST(state, payload) {
    state.checkOutList = payload
  },
  // 設定待確認訂單列表
  SET_WAIT_CONFIRM_LIST(state, payload) {
    state.waitConfirmList = payload
  },
  // 設定折扣列表
  SET_DISCOUNT_LIST(state, payload) {
    state.discountList = payload
  },
  // 設定footer的badge數字
  SET_BADGE_LIST(state, { key, value }) {
    state.badgeList[key] = value
  },
  // 設定待確認訂單聲音開關
  SET_WAIT_CONFIRM_SOUND_ON(state, payload) {
    state.waitConfirmSoundOn = payload
  },
}

const actions = {
  // 取得訂單列表
  getOrderList({ state, commit }, data = {}) {
    return new Promise((resolve, reject) => {
      data = {
        status: 0,
        created_at: 'ASC',
        ...data,
      }
      getOrderList(data).then((response) => {
        let { data } = response
        let waitConfirmOrder = {}
        let waitConfirmCount = 0
        let waitConfirmSoundOn = false
        // workStatus:order_details裡面的項目依照status區分
        data = data.map((item) => {
          item.workStatus = item.order_details?.reduce((acc, cur) => {
            if (acc[cur.status]) {
              acc[cur.status].push(cur)
            } else {
              acc[cur.status] = [cur]
            }
            return acc
          }, {})
          // 計算待確認訂單
          if (item.workStatus[0]) {
            waitConfirmOrder[item.id] = item
            waitConfirmCount++
            // 判斷有沒有已經存在
            if (!state.waitConfirmList[item.id]) {
              waitConfirmSoundOn = true
            }
          }
          return item
        })

        // 設定待確認訂單數量
        commit('SET_BADGE_LIST', { key: 'waitConfirm', value: waitConfirmCount })
        commit('SET_WAIT_CONFIRM_LIST', waitConfirmOrder)
        commit('SET_WAIT_CONFIRM_SOUND_ON', waitConfirmSoundOn)
        commit('SET_ORDER_LIST', data)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  // 取得待結帳訂單列表
  getWaitCheckOutList({ commit }, data = {}) {
    return new Promise((resolve) => {
      data = {
        status: 1,
        created_at: 'ASC',
        ...data,
      }
      getOrderList(data).then((response) => {
        let { data } = response
        commit('SET_WAIT_CHECKOUT_LIST', data)
        resolve(response)
      }).catch((error) => {
        console.log('getWaitCheckOutList error', error)
      })
    })
  },

  // 取得結帳訂單列表
  getCheckOutList({ commit }, data = {}) {
    return new Promise((resolve) => {
      data = {
        status: 2,
        created_at: 'DESC',
        start_at: moment().format('YYYY-MM-DD 00:00:00'),
        end_at: moment().format('YYYY-MM-DD 23:59:59'),
      }
      getOrderList(data).then((response) => {
        let { data } = response
        commit('SET_CHECKOUT_LIST', data)
        resolve(response)
      }).catch((error) => {
        console.log('getCheckOutList error', error)
      })
    })
  },
  // 取得折扣列表
  getDiscountList({ commit }, data = {}) {
    return new Promise((resolve) => {
      getDiscountList(data).then((response) => {
        let { data } = response
        commit('SET_DISCOUNT_LIST', data)
        resolve(response)
      }).catch((error) => {
        console.log('getDiscountList error', error)
      })
    })
  }
}

const getters = {
  // 取得訂單
  getOrder: (state) => (id) => {
    let orderData = state.orderList.find(order => order.id === id)
    if (!orderData) {
      orderData = state.waitCheckOutList.find(order => order.id === id)
    }
    return orderData
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
