import { login } from '@/api/auth'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { joinAdmin, disconnect } from '@/socket/auth'

const state = {
  token: getToken(),
  socketRoom: [],
  adminData: {},
  role: null,
}

const mutations = {
  SET_TOEKN: (state, token) => {
    state.token = token
    setToken(token)
  },
  SET_ROLE: (state, role) => {
    state.role = role
  },
  SET_ADMIN_DATA: (state, adminData) => {
    state.adminData = adminData
  },
  REMOVE_TOKEN: (state) => {
    state.token = null
    disconnect()
    removeToken()
  },
  SET_SOCKET_ROOM: (state, socketRoom) => {
    state.socketRoom.push(socketRoom)
  },
}

const actions = {
  // user login
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      login(data).then((response) => {
        commit('SET_ADMIN_DATA', response.data)
        commit('SET_TOEKN', response.data.token)
        joinAdmin()
        resolve(response)
      }).catch((error) => {
        removeToken()
        reject(error)
      })
    })
  },
  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('REMOVE_TOKEN')
      resolve()
    })
  },
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
