import socket from '@/utils/socket'
import store from '@/store'

// 工作區訂單列表更新通知
socket.on('orderUpdate', () => {
  store.dispatch('order/getOrderList').catch((error) => {
    console.error('getOrderList error', error)
  })
})

// 待結帳訂單列表更新通知
socket.on('waitCheckoutUpdate', () => {
  store.dispatch('order/getWaitCheckOutList')
})

// 結帳訂單列表更新通知
socket.on('checkoutUpdate', () => {
  store.dispatch('order/getCheckOutList')
})