import socket from '@/utils/socket'
import store from '@/store'

// 加入admin
export function joinAdmin() {
  // console.log("加入admin")
  socket.emit('adminConnect')
}

// 加入admin成功通知
socket.on('adminConnect', () => {
  // 檢查admin token
  const adminToken = store.state.auth.token
  if (!adminToken) {
    return
  } else {
    //  BASE64解碼
    const base64 = atob(adminToken.split('.')[1])
    //  JSON解析
    const adminData = JSON.parse(base64)
    //  檢查token是否過期
    const now = Math.floor(Date.now() / 1000)
    if (now > adminData.exp) {
      store.commit('auth/REMOVE_TOKEN')
    } else {
      // 設定角色
      store.commit('auth/SET_ROLE', adminData.roldKind)
    }
  }

  // 初始資料拉取
  // 工作區訂單
  store.dispatch('order/getOrderList').catch((error) => {
    console.error('getOrderList error', error)
  })
  // 待結帳訂單
  store.dispatch('order/getWaitCheckOutList').catch((error) => {
    console.error('getWaitCheckOutList error', error)
  })
  // 產品類型
  store.dispatch('product/getProductType').catch((error) => {
    console.error('getProductType error', error)
  })
  // 產品標籤
  store.dispatch('product/getProductTag').catch((error) => {
    console.error('getProductTag error', error)
  })
  // 付款方式
  store.dispatch('payment/getPayment', { status: 1 }).catch((error) => {
    console.error('getPayment error', error)
  })
})

// 註銷socket
export function disconnect() {
  socket.emit('adminDisconnect')
}
