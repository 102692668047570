<template>
    <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  created() {
    document.addEventListener('gesturestart', (event) => {
      // 阻止兩指縮放畫面
      event.preventDefault()
    })
  },
  mounted() {
  },
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300&display=swap');
</style>

<style lang="scss">
#app {
  height: 100vh;
}
</style>
