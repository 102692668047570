import {
  createRouter, createWebHashHistory,
} from 'vue-router'
import {
  joinAdmin,
} from '@/socket/auth'
import store from '@/store'
import HomeLayout from '@/layout/HomeLayout'

const routes = [
  {
    path: '/seat',
    component: HomeLayout,
    children: [
      {
        path: '/seat',
        component: () => import('@/views/SeatListView'),
        name: 'SeatView',
        meta: { title: '座位', noCache: true },
      },
    ],
  },

  {
    path: '/report',
    component: HomeLayout,
    children: [
      {
        path: '/report',
        component: () => import('@/views/ReportView'),
        name: "ReportView",
        meta: { title: '報表', noCache: true }
      }
    ]
  },
  {
    path: '/workstation',
    component: HomeLayout,
    children: [
      {
        path: '/workstation',
        component: () => import('@/views/WorkstationView'),
        name: 'WorkstationView',
        meta: {
          title: '工作站',
          noCache: true,
        },
      },
    ],
  },
  {
    path: '/setting',
    component: HomeLayout,
    children: [
      {
        path: '/setting',
        component: () => import('@/views/SettingView'),
        name: 'SettingView',
        redirect: '/setting/seat',
        meta: {
          title: '系統設定',
          noCache: true,
        },
        children: [
          {
            path: '/setting/seat',
            component: () => import('@/views/setting/SeatSettingView'),
            name: 'SeatSettingView',
            meta: {
              title: '座位設定',
              noCache: true
            }
          },
          {
            path: '/setting/product',
            component: () => import('@/views/setting/ProductView'),
            name: 'ProductView',
            meta: {
              title: '菜單列表',
              noCache: true
            }
          },
          {
            path: '/setting/productEdit/:id',
            component: () => import('@/views/setting/ProductEditView'),
            name: 'ProductEditView',
            meta: {
              title: '菜單設定',
              noCache: true
            }
          },
          {
            path: '/setting/comboGroup',
            component: () => import('@/views/setting/comboGroupView'),
            name: 'ComboGroupView',
            meta: {
              title: '套餐群組',
              noCache: true
            }
          },
          {
            path: '/setting/comboGroupEdit/:id',
            component: () => import('@/views/setting/comboGroupEditView'),
            name: 'ComboGroupEditView',
            meta: {
              title: '套餐群組設定',
              noCache: true
            }
          },
          {
            path: '/setting/admin',
            component: () => import('@/views/setting/AdminView'),
            name: 'AdminView',
            meta: {
              title: '管理員設定',
              noCache: true
            }
          },
          {
            path: '/setting/payment',
            component: () => import('@/views/setting/PaymentView'),
            name: 'PaymentView',
            meta: {
              title: '付款方式設定',
              noCache: true
            }
          },
          {
            path: '/setting/discount',
            component: () => import('@/views/setting/DiscountView'),
            name: 'DiscountView',
            meta: {
              title: '折扣設定',
              noCache: true
            }
          },
          {
            path: '/setting/other',
            component: () => import('@/views/setting/otherSettingView'),
            name: 'OtherSettingView',
            meta: {
              title: '其它設定',
              noCache: true
            }
          },
          {
            path: '/setting/category',
            component: () => import('@/views/setting/CategoryView'),
            name: 'CategoryView',
            meta: {
              title: '類別設定',
              noCache: true
            }
          },
          {
            path: '/setting/tag',
            component: () => import('@/views/setting/TagView'),
            name: 'TagView',
            meta: {
              title: '標籤設定',
              noCache: true
            }
          }
        ]
      },
    ],
  },
  {
    path: '/reservation',
    component: HomeLayout,
    children: [
      {
        path: '/reservation',
        component: () => import('@/views/ReserveView'),
        name: "ReservationView",
        meta: { title: '預約訂位', noCache: true }
      }
    ]
  },
  {
    path: '/checkout',
    component: HomeLayout,
    children: [
      {
        path: '/checkout',
        component: () => import('@/views/CheckOutView'),
        name: 'CheckOutView',
        meta: {
          title: '結帳',
          noCache: true,
        },
      },
    ],
  },
  {
    path: '/order',
    component: HomeLayout,
    children: [
      {
        path: '/order',
        component: () => import('@/views/menu/MenuOrderView'),
        name: 'OrderView',
        meta: {
          title: '新訂單',
          noCache: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView'),
    meta: {
      title: '登入',
      noCache: true,
    },
  },
  {
    path: '/401',
    name: 'NoPermission',
    component: () => import('@/views/error-page/401'),
    meta: {
      title: '401 No Permission',
      noCache: true,
    },
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import('@/views/error-page/404'),
    meta: {
      title: '404 Page Not Found',
      noCache: true,
    },
  },
  {
    path: '/:catchAll(.*)', // 匹配所有路径
    name: 'NotFound',
    redirect: '/404'
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
  scrollBehavior() {
    // always scroll to top
    return {
      top: 0,
    }
  },
})

// 路由守衛
router.beforeEach(async (to, from, next) => {
  // set title
  document.title = to.meta.title || '後台管理系統'

  // 檢查有無token
  if (to.fullPath.indexOf('login') === -1 && to.fullPath.indexOf('404') === -1 && to.fullPath.indexOf('401') === -1) {
    if (!store.state.auth.token) {
      next({
        path: '/401',
      })
    } else if (store.state.auth.socketRoom.indexOf('admin') === -1) { // socketRoom 是否有admin
      joinAdmin()
      store.commit('auth/SET_SOCKET_ROOM', 'admin')
    }
  }

  next()
})

export default router
