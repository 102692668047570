import request from '@/utils/request'

// 取得菜品類別
export function getProductType(params = {}) {
  const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')
  return request({
    url: `/dishCategory${queryString ? `?${queryString}` : ''}`,
    method: 'get'
  })
}

// 取得菜品列表
export function getProductList(params = {}) {
  const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')
  return request({
    url: `/product${queryString ? `?${queryString}` : ''}`,
    method: 'get'
  })
}

// 取得菜品詳情
export function getProductDetail(data) {
  const { id } = data
  const queryString = Object.keys(data).map((key) => `${key}=${data[key]}`).join('&')
  return request({
    url: `/product/${id}${queryString ? `?${queryString}` : ''}`,
    method: 'get',
  })
}

// 新增菜品類別
export function addCategory(data) {
  return request({
    url: '/dishCategory',
    method: 'post',
    data
  })
}

// 更新菜品類別
export function updateCategory(data) {
  const id = data.id
  return request({
    url: `/dishCategory/${id}`,
    method: 'patch',
    data
  })
}

// 刪除菜品類別
export function deleteCategory(id) {
  return request({
    url: `/dishCategory/${id}`,
    method: 'delete',
  })
}

// 取得菜品標籤
export function getProductTag(params = {}) {
  const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')
  return request({
    url: `/tag${queryString ? `?${queryString}` : ''}`,
    method: 'get'
  })
}

// 新增菜品標籤
export function addTag(data) {
  return request({
    url: '/tag',
    method: 'post',
    data
  })
}

// 更新菜品標籤
export function updateTag(data) {
  const id = data.id
  return request({
    url: `/tag/${id}`,
    method: 'patch',
    data
  })
}

// 刪除菜品標籤
export function deleteTag(id) {
  return request({
    url: `/tag/${id}`,
    method: 'delete',
  })
}

// 更新菜品
export function updateProduct(data) {
  const id = data.id
  return request({
    url: `/product/${id}`,
    method: 'patch',
    data
  })
}

// 新增菜品
export function addProduct(data) {
  return request({
    url: '/product',
    method: 'post',
    data
  })
}

// 刪除菜品
export function deleteProduct(id) {
  return request({
    url: `/product/${id}`,
    method: 'delete',
  })
}