import request from '@/utils/request'

// 送出訂單API
export function postOrder(data) {
  return request({
    url: '/order',
    method: 'post',
    data,
  })
}

// 取得座位訂單
export function getOrderSeat(seatId) {
  return request({
    url: `/order/seat/${seatId}`,
    method: 'get',
  })
}

// 鎖定訂單
export function lockOrder(id) {
  return request({
    url: `/order/${id}/lock`,
    method: 'post',
  })
}

// 取得訂單列表
export function getOrderList(data = {}) {
  // data轉成query string
  const queryString = Object.keys(data).map((key) => `${key}=${data[key]}`).join('&')
  return request({
    url: `/order${queryString ? `?${queryString}` : ''}`,
    method: 'get',
  })
}

// 取得訂單
export function getOrder(data = {}) {
  return request({
    url: `/order/${data.id}`,
    method: 'get',
  })
}

// 確認訂單
export function confirmOrder(id) {
  return request({
    url: `/order/${id}/confirm`,
    method: 'post',
  })
}

// 訂單項目推進
export function orderItemNext(data = {}) {
  return request({
    url: `/order/${data.id}/next/${data.detail_id}`,
    method: 'post',
    data,
  })
}

// 訂單項目推進陣列
export function orderItemNextArray(data = {}) {
  return request({
    url: `/order/${data.id}/nextArray`,
    method: 'post',
    data,
  })
}

// 取消訂單
export function cancelOrder(id) {
  return request({
    url: `/order/${id}/cancel`,
    method: 'post',
  })
}

// 折扣訂單
export function discountOrder(data) {
  return request({
    url: `/order/${data.id}/discount`,
    method: 'post',
    data,
  })
}

// 合併結帳
export function mergeCheckout(data) {
  return request({
    url: `/order/mergeCheckout`,
    method: 'post',
    data,
  })
}  

// 結帳
export function checkoutOrder(data) {
  return request({
    url: `/order/${data.id}/checkout`,
    method: 'post',
    data,
  })
}

// 取得折扣列表
export function getDiscountList() {
  return request({
    url: '/discount',
    method: 'get',
  })
}

// 修改折扣
export function updateDiscount(data) {
  const id = data.id
  return request({
    url: `/discount/${id}`,
    method: 'patch',
    data,
  })
}

// 新增折扣
export function addDiscount(data) {
  return request({
    url: '/discount',
    method: 'post',
    data,
  })
}

// 刪除折扣
export function deleteDiscount(id) {
  return request({
    url: `/discount/${id}`,
    method: 'delete',
  })
}

// 訂單更換桌號
export function changeSeat(data) {
  const id = data.id
  return request({
    url: `/order/${id}/changeSeat`,
    method: 'post',
    data,
  })
}

// 取消訂單項目
export function cancelOrderItem(data) {
  const id = data.id
  return request({
    url: `/order/${id}/cancelItem/${data.order_detail_id}`,
    method: 'post',
    data,
  })
}

// 取消訂單項目陣列
export function cancelOrderItemArr(data) {
  const id = data.id
  return request({
    url: `/order/${id}/cancelItems`,
    method: 'post',
    data,
  })
}

// 編輯餐點細項
export function updateOrderItem(data) {
  const id = data.id
  const detail_id = data.detail_id
  return request({
    url: `/order/${id}/updateItem/${detail_id}`,
    method: 'post',
    data,
  })
}