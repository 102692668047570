import axios from 'axios'
import store from '@/store'
import { ElMessage } from 'element-plus'
import { getToken, removeToken } from '@/utils/auth'
import ElMessageBox from '@/utils/comfirm'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (getToken() != null) {
      config.headers.Authorization = `Bearer ${getToken()}`
      // 不允許快取
      config.headers['Cache-Control'] = 'no-cache'
    }
    return config
  },
  (error) => Promise.reject(error),
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const httpCode = response.status
    const res = response.data
    if (httpCode !== 201 && httpCode !== 200) {
      ElMessage({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
      })
      return Promise.reject(res.message || 'Error')
    }
    return res
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      removeToken()
      ElMessageBox.confirm({
        message: '本次操作已逾時，請重新登入！',
        title: '已登出',
        confirmButtonText: '回登入',
        showCancelButton: false,
        type: 'error',
      }, () => {
        store.dispatch('auth/logout')
        location.href = '/#/login'
      }, () => {
        store.dispatch('auth/logout')
        location.href = '/#/login'
      })
    } else if (error.response.status >= 500) {
      ElMessage({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      })
    } else if (error.response.status >= 400 && error.response.status < 500) {
      return Promise.reject(error.response.data)
    }
    // console.error('err:' + error) // for debug
    return Promise.reject(error)
  },
)

export default service
