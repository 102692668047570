import request from '@/utils/request'

// 取得座位列表
export function getSeat() {
  return request({
    url: '/seat',
    method: 'get',
  })
}

// 新增座位
export function addSeat(data) {
  return request({
    url: '/seat',
    method: 'post',
    data,
  })
}

// 更新座位
export function updateSeat(data) {
  const { id } = data
  return request({
    url: `/seat/${id}`,
    method: 'patch',
    data,
  })
}

// 刪除座位
export function deleteSeat(id) {
  return request({
    url: `/seat/${id}`,
    method: 'delete',
  })
}

// 取得預約列表
export function getReserveList(data = {}) {
  // data轉成query string
  const queryString = Object.keys(data).map((key) => `${key}=${data[key]}`).join('&')
  return request({
    url: '/reservation' + (queryString ? `?${queryString}` : ''),
    method: 'get',
  })
}

// 取消預約
export function cancelReserve(id) {
  return request({
    url: `/reservation/${id}`,
    method: 'delete',
  })
}

// 預約座位
export function reserveSeat(data) {
  return request({
    url: '/reservation',
    method: 'post',
    data,
  })
}

// 產生座位QR
export function generateSeatQR(id) {
  return request({
    url: `/seat/${id}/generateQRCode`,
    method: 'get',
  })
}

// 清空座位
export function clearSeat(id) {
  return request({
    url: `/seat/clear/${id}`,
    method: 'post',
  })
}

// 客人入座
export function guestSit(id) {
  return request({
    url: `/seat/guestSit/${id}`,
    method: 'post',
  })
}