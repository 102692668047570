import { getSeat, getReserveList } from '@/api/seat.js'
// eslint-disable-next-line no-unused-vars
import * as socket from '@/socket/seat'

const state = {
  seatList: {},
  seatStatus: {
    0: '閒置',
    1: '用餐中',
    2: '已預約',
  },
  // 預約列表
  reserveList: [],
  // 座位的預約列表
  seatReserveList: {},
}

const mutations = {
  SET_SEAT(state, payload) {
    state.seatList = payload
  },
  SET_RESERVE_LIST(state, payload) {
    // 轉成MAP
    const reserveMap = {}
    payload.forEach((item) => {
      item.reservationDetails.forEach((detail) => {
        if (!reserveMap[detail.seat_id]) {
          reserveMap[detail.seat_id] = []
        }
        reserveMap[detail.seat_id].push(item)     
      })
    })
    state.seatReserveList = reserveMap
    state.reserveList = payload
  }
}

const actions = {
  async getSeat({ commit }) {
    return new Promise((resolve) => {
      getSeat().then((response) => {
        // 轉成MAP
        const map = {}
        for (let i = 0; i < response.data.length; i++) {
          map[response.data[i].id] = response.data[i]
        }
        commit('SET_SEAT', map)
        resolve(response.data)
      }).catch((error) => {
        console.error('seat/getSeat error', error)
      })
    })
  },
  // 取得預約列表
  async getReserveList({ commit }, paras = {}) {
    return new Promise((resolve) => {
      getReserveList(paras).then((response) => {
        commit('SET_RESERVE_LIST', response.data)
        resolve(response)
      }).catch((error) => {
        console.error('seat/getReserveList error', error)
      })
    })
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
