import { getProductType, getProductList, getProductTag } from '@/api/product'
// eslint-disable-next-line no-unused-vars
import * as socket from '@/socket/product'

const state = {
  productList: {},
  productType: [],
  productTag: [],
}

const mutations = {
  // 菜品類別
  SET_PRODUCT_TYPE: (state, data) => {
    state.productType = data
  },
  // 菜品列表
  SET_PRODUCT_LIST: (state, data) => {
    state.productList = data
  },
  // 菜品標籤
  SET_PRODUCT_TAG: (state, data) => {
    state.productTag = data
  }
}

const actions = {
  // 取得菜品類別
  getProductType({ commit }) {
    return new Promise((resolve, reject) => {
      getProductType().then((response) => {
        commit('SET_PRODUCT_TYPE', response.data)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  // 取得菜品列表
  getProductList({ commit }) {
    return new Promise((resolve) => {
      const paras = {
        is_displayed: 1,
        is_deleted: 0,
        is_available: 1
      }
      getProductList(paras).then((response) => {
        const data = response.data.reduce((acc, cur) => {
          const key = cur.dish_category_id
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(cur)
          return acc
        }, {})
        commit('SET_PRODUCT_LIST', data)
        resolve(response.data)
      }).catch((error) => {
        console.log('getProductList error', error)
      })
    })
  },

  // 取得菜品標籤
  getProductTag({ commit }) {
    return new Promise((resolve, reject) => {
      getProductTag().then((response) => {
        commit('SET_PRODUCT_TAG', response.data)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
