import { getPayment } from '@/api/payment'
// eslint-disable-next-line no-unused-vars
import * as socket from '@/socket/payment'

const state = {
    paymentList: [],
    paymentKeyValue: {}
}

const mutations = {
    SET_PAYMENT_LIST: (state, data) => {
        state.paymentList = data
        state.paymentKeyValue = {}
        data.forEach((item) => {
            state.paymentKeyValue[item.id] = item
        })
    },
}

const actions = {
    // 取得付款方式
    getPayment({ commit }, data = {}) {
        return new Promise((resolve) => {
            getPayment(data).then((response) => {
                let { data } = response
                commit('SET_PAYMENT_LIST', data)
                resolve(response)
            }).catch((error) => {
                console.log('getPayment error', error)
            })
        })
    },
}

const getters = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
