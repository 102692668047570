<template>
    <div class="audio-div">
        <audio ref="audioPlayer" :loop="loop">
            <source :src="audioSource" type="audio/mpeg">
        </audio>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'AudioPlay',
    components: {},
    data() {
        return {
            audioSource: null,
            loop: false,
            isMuted: false,
            isWaiting: false,
        };
    },
    watch: {
        audioOpen: {
            handler(val) {
                if (val) {
                    this.startAudioOpen();
                }
            },
            immediate: true,
        },
        audioPlay: {
            handler(val) {
                if (!val) {
                    this.startAudioOpen();
                }
            }
        },
        playList: {
            handler(val) {
                if (val.length > 0) {
                    this.startAudioOpen();
                }
            },
            deep: true,
        },
    },
    computed: {
        ...mapState('app', ['audioOpen', 'audioPlay', 'playList']),
    },
    created() { },
    mounted() { },
    methods: {
        startAudioOpen() {
            try {
                if (this.playList.length > 0 && this.audioPlay) {
                    this.isWaiting = false;
                    const playConfig = this.playList[0];
                    this.$store.commit('app/SHIFT_PLAY_LIST');
                    this.audioSource = playConfig.audioSource;
                    this.loop = playConfig.loop;
                    this.$refs.audioPlayer.load(); // 重新載入音效
                    this.$refs.audioPlayer.play().catch(err => {
                        console.error('音訊播放錯誤：', err);
                    });
                } else if (!this.isWaiting) { // 沒有音訊的時候需要播放空白音訊，避免瀏覽器失去播放權限
                    this.isWaiting = true;
                    this.audioSource = '/sound/null.mp3';
                    this.loop = true;
                    this.$refs.audioPlayer.load(); // 重新載入音效
                    this.$refs.audioPlayer.play().catch(err => {
                        console.error('音訊播放錯誤：', err);
                    });
                }
            } catch (err) {
                console.error('音訊播放錯誤：', err);
            }
        },
    },
};
</script>

<style scoped>
.audio-div {
    position: absolute;
    z-index: -1;
}
</style>