import socket from '@/utils/socket'
import store from '@/store'
import moment from 'moment'

socket.on('seatUpdate', () => {
  store.dispatch('seat/getSeat')
})

// 預約列表更新
socket.on('reservationUpdate', () => {
  // console.log('reservationUpdate')
  const start_at = moment().format('YYYY-MM-DD HH:mm:ss');
  const end_at = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
  store.dispatch('seat/getReserveList', {
    start_at,
    end_at
  })
})
