<template>
  <div class="main" @click="touchFirst">
    <router-view />
    <footerView />
  </div>
</template>

<script>
import footerView from '@/layout/footer.vue'

export default {
  name: 'HomeLayout',
  components: { footerView },
  data() {
    return {
    }
  },
  methods: {
    touchFirst() {
      if (!this.$store.state.app.audioOpen) {
        this.$store.commit('app/SET_AUDIO_OPEN', true)
      }
    },
  },
}
</script>
