import request from '@/utils/request'

// 取得網站設定
export function getWebConfig() {
  return request({
    url: 'webConfig',
    method: 'get',
  })
}

// 更新網站設定
export function updateWebConfig(data) {
  const id = data.id
  return request({
    url: `webConfig/${id}`,
    method: 'patch',
    data,
  })
}