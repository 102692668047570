// 全域的確認視窗
import { ElMessageBox } from 'element-plus'

export default {
  confirm({ ...option }, callback = null, cancel = null) {
    ElMessageBox.confirm(
      option.content,
      option.title || '提示',
      {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
        center: true,
        customClass: 'confirm-box',
        ...option,
      },
    )
      .then(callback)
      .catch((err) => {
        if (cancel) {
          cancel(err)
        }
      })
  },
  // 可輸入的確認視窗
  prompt({ ...option }, callback = null, cancel = null) {
    ElMessageBox.prompt(
      option.content,
      option.title || '提示',
      {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
        center: true,
        customClass: 'confirm-box',
        width: '70%',
        ...option,
      },
    )
      .then(callback)
      .catch((err) => {
        if (cancel) {
          cancel(err)
        }
      })
  }
}
