import { getWebConfig } from '@/api/app'
import Cookies from 'js-cookie'

const state = {
  webConfig: new Map(),
  audioOpen: false,
  audioPlay: true,
  playList: [],
  waitCheckOrderNotice: Cookies.get('waitCheckOrderNotice') === undefined ? true : Cookies.get('waitCheckOrderNotice') === 'true',
  infieldOrderNotice: Cookies.get('infieldOrderNotice') === undefined ? true : Cookies.get('infieldOrderNotice') === 'true',
}

const mutations = {
  SET_WEB_CONFIG: (state, data) => {
    state.webConfig = data
  },
  SET_AUDIO_OPEN: (state, data) => {
    state.audioOpen = data
  },
  ADD_PLAY_LIST: (state, data) => {
    if (!state.audioOpen) return
    state.playList.push(data)
    state.audioPlay = true
  },
  SHIFT_PLAY_LIST: (state) => {
    return state.playList.shift()
  },
  SET_AUDIO_PLAY: (state, data) => {
    state.audioPlay = data
  },
  SET_WAIT_CHECK_ORDER_NOTICE: (state, data) => {
    state.waitCheckOrderNotice = data
    Cookies.set('waitCheckOrderNotice', data)
  },
  SET_INFIELD_ORDER_NOTICE: (state, data) => {
    state.infieldOrderNotice = data
    Cookies.set('infieldOrderNotice', data)
  },
}

const actions = {
  getWebConfig({ commit }) {
    return new Promise((resolve, reject) => {
      getWebConfig().then((response) => {
        // 轉成MAP
        const map = new Map()
        for (let i = 0; i < response.data.length; i++) {
          map.set(response.data[i].kind, response.data[i].content)
        }
        commit('SET_WEB_CONFIG', map)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
