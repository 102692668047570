import Cookies from 'js-cookie'

const TokenKey = 'posToken'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  // 把token存入cookie 有效期為1天
  return Cookies.set(TokenKey, token, { expires: 1 })
}

export function removeToken() {
  // const cookies = Cookies.get()
  Cookies.remove(TokenKey)
  // Object.keys(cookies).forEach((name) => {
  //   Cookies.remove(name)
  // })
  return true
}
