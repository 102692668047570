export default {
  /**
     * 10000 => "10,000"
     * @param {number} num
    */
  toThousandFilter(num) {
    return (+num || 0).toString().replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
  },

  /**
     * 2022-05-10T01:55:23.491Z => YYYY-mm-dd HH:ii:ss
     * @param {*} dt
     */
  dateFormat(dt) {
    if (!dt) return ''
    dt = new Date(dt)
    const f = function (d) { return d < 10 ? `0${d}` : d }
    return `${dt.getFullYear()}-${f(dt.getMonth() + 1)}-${f(dt.getDate())} ${f(dt.getHours())}:${f(dt.getMinutes())}:${f(dt.getSeconds())}`
  },

  /**
     * 2022-05-10T01:55:23.491Z => YYYY-mm-dd HH:ii:ss
     * @param {*} dt
     */
  dayFormat(dt) {
    if (!dt) return ''
    dt = new Date(dt)
    const f = function (d) { return d < 10 ? `0${d}` : d }
    return `${dt.getFullYear()}-${f(dt.getMonth() + 1)}-${f(dt.getDate())}`
  },

  /**
     * 2022-05-10T01:55:23.491Z => HH:ii
     * @param {*} dt
     */
  timeFormat(dt) {
    if (!dt) return ''
    dt = new Date(dt)
    const f = function (d) { return d < 10 ? `0${d}` : d }
    return `${f(dt.getHours())}:${f(dt.getMinutes())}`
  }

}
