import request from '@/utils/request'

// 取得付款方式
export function getPayment(data = {}) {
  // data轉成query string
  const queryString = Object.keys(data).map((key) => `${key}=${data[key]}`).join('&')
  return request({
    url: 'payment?' + queryString,
    method: 'get'
  })
}

// 新增付款方式
export function createPayment(data) {
  return request({
    url: 'payment',
    method: 'post',
    data
  })
}

// 修改付款方式
export function updatePayment(data) {
  const id = data.id
  delete data.id
  return request({
    url: 'payment/' + id,
    method: 'patch',
    data
  })
}

// 刪除付款方式
export function deletePayment(id) {
  return request({
    url: 'payment/' + id,
    method: 'delete'
  })
}