import request from '@/utils/request'

// 使用者入座
export function login(data) {
  return request({
    url: 'admin/login',
    method: 'post',
    data,
  })
}
