import io from 'socket.io-client'

const socket = io(process.env.VUE_APP_SOCKET_API)


// 連結錯誤
socket.on('error', (err) => {
    console.log('connect_error', err)
})

export default socket
