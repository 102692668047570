import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhTw from 'element-plus/dist/locale/zh-tw.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import router from './router'
import store from './store'

// filter
import filters from './utils/filters' // global filters

// filter
import socket from './utils/socket' // global filters

// Element Plus
import 'element-plus/dist/index.css'

// import './icons' // icon
import components from '@/components/index'

import '@/styles/index.scss' // global css

// 全域的確認視窗
import messageBox from './utils/comfirm'

import moment from 'moment';

// QRCode
import VueQrcode from '@chenfengyuan/vue-qrcode';

// element-plus 報錯 ResizeObserver loop limit exceeded 解決方案
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
//-------------------------------------------------------------


const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus, {
  locale: zhTw,
})
app.use(components)

app.config.globalProperties.$filters = filters
app.config.globalProperties.$socket = socket
app.config.globalProperties.$messageBox = messageBox
app.config.globalProperties.$moment = moment

app.component(VueQrcode.name, VueQrcode);

// Quill 富文本編輯器
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

app.component('QuillEditor', QuillEditor);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.mount('#app')
