<template>
  <div class="footer-wrapper">
    <template v-for="item in menuButtonData" :key="item.name">
      <router-link v-if="item.name !== '報表' || role === 0" :to="item.url">
        <el-badge :hidden="item.badgInfo <= 0" :value="item.badgInfo" class="item" :max="99">
          <el-button link :class="[{ 'is_active': checkActive(item.url) }]">
            <el-icon>
              <component :is="item.icon" />
            </el-icon>
            {{ item.name }}
          </el-button>
        </el-badge>
      </router-link>
    </template>
    <audioPlay></audioPlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FooterView',
  components: {
  },
  computed: {
    ...mapState('order', ['badgeList', 'orderList', 'waitConfirmSoundOn']),
    ...mapState('app', ['waitCheckOrderNotice']),
    ...mapState('auth', ['role']),
  },
  watch: {
    'badgeList.workstation': {
      handler() {
        this.menuButtonData[4].badgInfo = this.badgeList.workstation;
      },
      immediate: true,
    },
    'badgeList.waitCheckOut': {
      handler() {
        this.menuButtonData[5].badgInfo = this.badgeList.waitCheckOut;
      },
      immediate: true,
    },
    'waitConfirmSoundOn': {
      handler(value) {
        if (value && this.waitCheckOrderNotice) {
          this.$store.commit('app/ADD_PLAY_LIST', {
            audioSource: '/sound/Pikachu.mp3',
            loop: true,
          });
          this.$messageBox.confirm({
            title: '新訂單',
            message: `有待確認訂單`,
            showCancelButton: false,
            type: 'info',
          }, () => {
            this.$store.commit('app/SET_AUDIO_PLAY', false);
            this.$store.commit('order/SET_WAIT_CONFIRM_SOUND_ON', false);
          }, () => {
            this.$store.commit('app/SET_AUDIO_PLAY', false);
            this.$store.commit('order/SET_WAIT_CONFIRM_SOUND_ON', false);
          });
        }
      },
    },
  },
  data() {
    return {
      menuButtonData: [
        {
          name: '設定',
          icon: 'Setting',
          url: '/setting',
        },
        {
          name: '報表',
          icon: 'Tickets',
          url: '/report',
        },
        {
          name: '座位',
          icon: 'Place',
          url: '/seat',
        },
        {
          name: '預約',
          icon: 'Clock',
          url: '/reservation',
        },
        {
          name: '工作站',
          icon: 'MessageBox',
          url: '/workstation',
          badgInfo: 0,
          badgNmae: 'workstation',
        },
        {
          name: '結帳',
          icon: 'Money',
          url: '/checkout',
          badgInfo: 0,
          badgNmae: 'waitCheckOut',
        },
        {
          name: '新訂單',
          icon: 'DocumentAdd',
          url: '/order',
        },
      ],
    }
  },
  created() { },
  mounted() { },
  methods: {
    checkActive(url) {
      // 如果是setting的子頁面，則回傳true
      if (url.includes('/setting')) {
        return this.$route.path.includes('/setting');
      }
      return this.$route.path === url;
    },
  },
}
</script>
